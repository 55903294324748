var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vx-card",
        [
          _c(
            "div",
            { staticClass: "flex flex-wrap justify-between items-center" },
            [
              _c(
                "div",
                { staticClass: "mb-4 md:mb-0 mr-4 ag-grid-table-actions-left" },
                [
                  _c(
                    "vs-dropdown",
                    {
                      staticClass: "cursor-pointer",
                      attrs: { "vs-trigger-click": "" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium",
                        },
                        [
                          _c("span", { staticClass: "mr-2" }, [
                            _vm._v(_vm._s(_vm.GeneratePageSizeButton())),
                          ]),
                          _c("feather-icon", {
                            attrs: {
                              icon: "ChevronDownIcon",
                              svgClasses: "h-4 w-4",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "vs-dropdown-menu",
                        [
                          _c(
                            "vs-dropdown-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.setPageSize(20)
                                },
                              },
                            },
                            [_c("span", [_vm._v("20")])]
                          ),
                          _c(
                            "vs-dropdown-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.setPageSize(50)
                                },
                              },
                            },
                            [_c("span", [_vm._v("50")])]
                          ),
                          _c(
                            "vs-dropdown-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.setPageSize(100)
                                },
                              },
                            },
                            [_c("span", [_vm._v("100")])]
                          ),
                          _c(
                            "vs-dropdown-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.setPageSize(150)
                                },
                              },
                            },
                            [_c("span", [_vm._v("150")])]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "flex flex-wrap items-center justify-between ag-grid-table-actions-right",
                },
                [
                  _c("vs-input", {
                    staticClass: "mb-4 md:mb-0 mr-4",
                    attrs: { placeholder: "Buscar..." },
                    on: { input: _vm.updateSearchQuery },
                    model: {
                      value: _vm.searchQuery,
                      callback: function ($$v) {
                        _vm.searchQuery = $$v
                      },
                      expression: "searchQuery",
                    },
                  }),
                  _vm._t("extraHeaderActions"),
                  _c(
                    "vx-tooltip",
                    { attrs: { text: "Refrescar datos" } },
                    [
                      _c("vs-button", {
                        staticClass: "mb-4 md:mb-0 mr-4",
                        attrs: {
                          radius: "",
                          color: "primary",
                          type: "border",
                          "icon-pack": "feather",
                          icon: "icon-refresh-cw",
                        },
                        on: { click: _vm.updateData },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "vx-tooltip",
                    { attrs: { color: "dark", text: "Limpiar filtros" } },
                    [
                      _c("vs-button", {
                        staticClass: "mb-4 md:mb-0",
                        attrs: {
                          radius: "",
                          color: "danger",
                          type: "border",
                          "icon-pack": "feather",
                          icon: "icon-x",
                        },
                        on: { click: _vm.resetFilters },
                      }),
                    ],
                    1
                  ),
                ],
                2
              ),
            ]
          ),
          _c("ag-grid-vue", {
            ref: "agGridTable",
            staticClass: "ag-theme-material w-100 my-4 ag-grid-table",
            attrs: {
              gridOptions: _vm.gridOptions,
              columnDefs: _vm.columns,
              defaultColDef: _vm.defaultColDef,
              rowData: _vm.data,
              rowSelection: "multiple",
              rowMultiSelectWithClick: "true",
              enableRangeSelection: true,
              colResizeDefault: "shift",
              animateRows: true,
              getContextMenuItems: _vm.getContextMenuItems,
              pagination: true,
              paginationPageSize: _vm.paginationPageSize,
              suppressPaginationPanel: true,
              tooltipShowDelay: "0",
              frameworkComponents: _vm.frameworkComponents,
            },
            on: { "selection-changed": _vm.onSelection },
          }),
          _c("vs-pagination", {
            attrs: { total: _vm.totalPages, max: _vm.maxPageNumbers },
            on: { input: _vm.onPageChange },
            model: {
              value: _vm.currentPage,
              callback: function ($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }