import moment from "moment";

  //Get data from ODOO

  //Clients
  // "billing_information_address_street": "",
  // "billing_information_address_number": "",
  // "billing_information_address_additional_info": "",
  // "billing_information_address_zip_code": "",
  // "billing_information_address_country": "AR",
  // "billing_information_address_state": "",
  // "billing_information_address_city": "",
  export const addressGetter = (params) => {
    const billingInformation = params.data.billing_information
    const address = billingInformation ? billingInformation.address : '-'

    return address.trim()
  }

  //billing
  export const taxIdGetter = (params)=> {
    const billingInformation = params.data.billing_information
    const taxId = billingInformation ? billingInformation.tax_id : '-'

    return taxId || '-'
  }
  export const legalNameGetter = (params)=> (params.data.billing_information ? params.data.billing_information.fantasy_name : '-') // es?
  export const idClientGetter = (params) => (params.data.id)
  export const fantasyNameGetter = (params) => (params.data.name)
  export const billingMomentGetter = (params)=> {
    const existProperties = params.data.properties
    const billingMoment = existProperties ? existProperties.billing_moment : '-'

    return billingMoment || '-'
  }
  export const paymentModeGetter = (params)=> {
    const existProperties = params.data.properties
    const paymentMethod = existProperties ? existProperties.payment_mode : '-'

    return paymentMethod || '-'
  }
  export const paymentTimeLimitGetter = (params)=> {
    const existProperties = params.data.properties
    const timeLimit = existProperties ? existProperties.payment_term : '-'

    return timeLimit || '-'
  }

  export const chargeTimeLimitGetter = (params)=> {
    const existProperties = params.data.properties
    const timeLimit = existProperties ? existProperties.charge_term : '-'

    return timeLimit || '-'
  }

  export const agencyCommissionModeGetter = (params)=> {
    const existProperties = params.data.properties
    const agencyCommissionMode = existProperties ? existProperties.agency_commission_mode : '-'

    return agencyCommissionMode || '-'
  }

  export const billingDateGetter = params => ('-');


  //charges
  export const billingNumberGetter = (params)=> ('-')
  export const expirationBillingDateGetter = params => ('-');

  //closings
  export const guaranteeGetter = (params)=> (params.data.guarantee ? "Si" : "No") //guarantee aún no existe
  export const finalAmountGetter = (params)=> {
    //Si es garantizado, toma 'estimado', si no es garantizado toma 'consumido'
    let value = params.data.guarantee ? params.data.paid_amount : params.data.total_price_consumed
    

    if (!value) return 0
    if (typeof value === 'string' && value.includes(','))
      value = value.replaceAll(',', '')
    var twoDecimals = parseFloat(value).toFixed(2)
    if (isNaN(twoDecimals)) return 'Número invalido'
    var formatPrice = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2
    }).format(twoDecimals)
    return `$ ${formatPrice}`
  }

  export const finalWithTaxGetter = (params)=> {
    //Si es garantizado, toma 'estimado', si no es garantizado toma 'consumido'
    const value = params.data.guarantee ? params.data.paid_amount : params.data.total_price_consumed
    const tax = params.data.tax ? parseFloat(params.data.tax) : 0

    if (!value) return 0
    if (!tax) return value

    let finalWithTax = parseFloat(value) + (parseFloat(value) * (tax/100))

    if (!finalWithTax) return 0
    if (typeof finalWithTax === 'string' && finalWithTax.includes(','))
      finalWithTax = finalWithTax.replaceAll(',', '')
    var twoDecimals = parseFloat(finalWithTax).toFixed(2)
    if (isNaN(twoDecimals)) return 'Número invalido'
    var formatPrice = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2
    }).format(twoDecimals)
    return `$ ${formatPrice}`
  }

  export const latePaymentGetter = params => {
    const expirationBillingDate = params.data.due_date;

    if (!expirationBillingDate) return '-'

    // const expirationBillingDate = new Date('2024-09-10')
    const today = new Date()

    const diference = today - expirationBillingDate

    const daysUntilExpiration = Math.floor(diference / (1000 * 60 * 60 * 24));

    return daysUntilExpiration
  };

  export const paymentDateGetter = params => dateFormatter(params.data.payment.payment_date);
  export const paymentDelayGetter = params => {
    if (!params.data.payment.payment_date) return '-'

    const paymentDate = new Date(params.data.payment.payment_date)
    const today = new Date()

    const diference = today - paymentDate

    const daysUntilExpiration = Math.floor(diference / (1000 * 60 * 60 * 24));

    return daysUntilExpiration > 0 ? `${daysUntilExpiration} días de atraso` : `Faltan ${daysUntilExpiration * -1} días para el pago`
  }


  //
  export const fullNameGetter = params => {
    const strName = `${params.data.billing_information_first_name || ''} ${params.data.billing_information_last_name || ''}`;
    const strNameTrim = strName.trim();
  
    return strNameTrim ? strName : (params.data.company_name ? params.data.company_name : '-');
  };
  
  export const emailGetter = params => (params.data.company_id ? params.data.email : '-');
  
  export const phoneGetter = params => (params.data.company_id ? params.data.phone : '-');
  
  export const countryGetter = params => (params.data.invoice_issuing_country_name ? params.data.invoice_issuing_country_name : `${params.data.billing_information_address_country || ''} ${params.data.billing_information_address_state || ''}`);
  
  export const campaignTypeNameGetter = params => (params.data.smart_campaign ? 'Programática' : 'Tradicional');
  
  export const agencyProfitGetter = params => parseFloat(params.data.total_price_with_profit - params.data.total_price_with_commission).toFixed(2);
  
  export const agencyProfitPercentagetoolTipValueGetter = params => {
    const totalValue = params.data.total_price_with_profit;
    const agencyProfit = parseFloat(params.data.total_price_with_profit - params.data.total_price_with_commission).toFixed(2);
    const profitPercentage = parseFloat((agencyProfit * 100) / totalValue).toFixed(2);
  
    return { profitPercentage: !isNaN(profitPercentage) ? profitPercentage : 0.0 };
  };
  
  export const complianceGetter = params => (params.data.total_shows_consumed || params.data.total_shows_consumed === 0 ? `${parseFloat((params.data.total_shows_consumed * 100) / params.data.total_promised_shows).toFixed(2)}%` : '-');
  export const newComplianceGetter = params => {
    const {promised_shows, impressions_showed} = params.data.payment

    if(promised_shows && !impressions_showed) return '0%'

    if(!promised_shows || impressions_showed === 0) return '-'

    return `${parseFloat((impressions_showed * 100) / promised_shows).toFixed(2)}%`
  }
  
  export const platformProfitGetter = params => parseFloat(parseFloat(params.data.total_price_with_commission) - parseFloat(params.data.total_price) - parseFloat(params.data.withholding_tax_amount)).toFixed(2);
  
  export const latinAdProfitGetter = params => {
    const oldProfit = parseFloat(parseFloat(parseFloat(params.data.total_price_with_commission) - parseFloat(params.data.total_price) - parseFloat(params.data.withholding_tax_amount)) * params.data.percentage_profit_platform / 100).toFixed(2);
    const newProfit = parseFloat(parseFloat(params.data.total_price * (params.data.markup_platform / 100)) * (params.data.percentage_profit_platform / 100)).toFixed(2);
  
    return newProfit > 0 ? newProfit : oldProfit;
  };
  
  export const partnerProfitGetter = params => parseFloat(parseFloat(params.data.total_price * (params.data.markup_partner / 100))).toFixed(2);
  
  export const exchangeProfitGetter = params => parseFloat(parseFloat(params.data.total_price_with_profit * (params.data.markup_external_dsp / 100))).toFixed(2);
  
  export const platformProfitPercentagetoolTipValueGetter = params => {
    const totalValue = params.data.total_price_with_profit;
    const platformProfit = parseFloat(params.data.total_price_with_commission - params.data.total_price).toFixed(2);
    const profitPercentage = parseFloat((platformProfit * 100) / totalValue).toFixed(2);
  
    return { profitPercentage: !isNaN(profitPercentage) ? profitPercentage : 0.0 };
  };
  
  export const createdAtGetter = params => (params.data.created_at ? moment(params.data.created_at, 'YYYY-MM-DD').format('DD/MM/YYYY') : '-');
  
  export const gatewayLastUpdateGetter = params => (params.data.gateway_last_update ? moment(params.data.gateway_last_update, 'YYYY-MM-DD').format('DD/MM/YYYY') : '-');
  
  export const startDateGetter = params => (params.data.start_date ? moment(params.data.start_date, 'YYYY-MM-DD').format('DD/MM/YYYY') : '-');
  
  export const endDateGetter = params => (params.data.end_date ? moment(params.data.end_date, 'YYYY-MM-DD').format('DD/MM/YYYY') : '-');
  
  export const taxGetter = params => (params.data.tax ? `${parseFloat(params.data.tax)}%` : '-');
  // New data structure from backend 
  export const newTaxGetter = params => (params.data.payment.tax ? `${parseFloat(params.data.payment.tax)}%` : '-');
  
  export const withholdingTaxGetter = params => (params.data.withholding_tax ? `${parseFloat(params.data.withholding_tax)}%` : '-');
  
  export const dateFormatter = params => (params ? moment(params, 'YYYY-MM-DD').format('DD/MM/YYYY') : '-');
  
  export const percentageFormatter = params => (params ? `${parseFloat(params)}%` : '-');
  
  export const currencyFormatter = params => {
    let value = params.value

    if (!value) return 0
    if (typeof value === 'string' && value.includes(','))
      value = value.replaceAll(',', '')
    var twoDecimals = parseFloat(value).toFixed(2)
    if (isNaN(twoDecimals)) return 'Número invalido'
    var formatPrice = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2
    }).format(twoDecimals)
    return `$ ${formatPrice}`
  };
  
  export const currencyCheck = params => (params.value ? currencyFormatter(params) : '-');
  
  export const paymentMethodGetter = params => {
    const gateway = {
      publinet: 'Publinet',
      mercadopago_basic: 'Mercadopago',
      mercadopago_custom: 'Mercadopago'
    };
  
    return params.data.gateway ? `${gateway[params.data.gateway]} (${params.data.payment_method})` : '-';
  };
  
  export const userNameGetter = params => `${params.data.first_name} ${params.data.last_name}`;
  
  export const companyNameGetter = params => (params.data.company_id ? `${params.data.company_name} (${params.data.company_id})` : '-');
  
  export const holdingIdGetter = params => (params.data.holding_id || '-');

  export const holdingNameGetter = params => (params.data.holding_id ? `${params.data.holding_name} (${params.data.holding_id})` : '-');
  // New data structure from backend 
  export const newHoldingNameGetter = params => (params.data.company.holding ? `${params.data.company.holding.name} (${params.data.company.holding.id})` : '-');
  export const programmaticProviderNameGetter = params => (params.data.campaign.programmatic_provider ? `${params.data.campaign.programmatic_provider.name}` : '-');

  
  export const campaignCostWithTaxGetter = params => parseFloat(parseFloat(params.data.total_price_with_profit) * (1 + (parseFloat(params.data.tax) / 100))).toFixed(2);
  
  export const amountReceivableWithTaxGetter = params => parseFloat(parseFloat(params.data.total_price_with_commission) * (1 + (parseFloat(params.data.tax) / 100))).toFixed(2);
  
  export const externalDspGetter = ({ data }) => (data.external_dsp_id ? `${data.external_dsp_name} (${data.external_dsp_exchange})` : 'LatinAd');
  // New data structure from backend 
  export const newExternalDspGetter = ({ data }) => (data.campaign.external_dsp_id ? `${data.campaign.external_dsp_name} (${data.campaign.external_dsp_exchange})` : 'LatinAd');

  export const bussinessModelGetter = ({ data }) => (data.external_dsp_id ? 'DSP externo' : 'DSP propio');

  export const paymentComplianceGetter = (params) => params.data.impressions_showed || params.data.impressions_showed === 0 ? `${parseFloat((params.data.impressions_showed * 100) / params.data.promised_shows).toFixed(2)}%` : '-'
  
  export const rateFromGetter = (params) => params.data.paid_amount_rates.from ? params.data.paid_amount_rates.from.currency : '-'

  export const rateFromGetterValue = (params) => params.data.paid_amount_rates.from ? params.data.paid_amount_rates.from.rate : '-'

  export const rateToGetter = (params) => params.data.paid_amount_rates.to ? params.data.paid_amount_rates.to.currency : '-'
  
  export const amountToPayWithTaxesGetter = (params) => parseFloat(parseFloat(params.data.paid_amount_local_currency) + (parseFloat(params.data.paid_amount_local_currency) * (parseFloat(params.data.tax) / 100)) + (parseFloat(params.data.paid_amount_local_currency) * (parseFloat(params.data.withholding_tax) / 100))).toFixed(2)
  
  export const fromDateGetter = (params) => dateFormatter(params.data.campaign.start_date)
  
  export const toDateGetter = (params) => dateFormatter(params.data.campaign.end_date)


  export const multiMonthGetter = (params) => {
    const {is_campaign_longer_than_one_month} = params.data.campaign

    return is_campaign_longer_than_one_month ? "Si" : "No"
  }

  export const totalPromisedShowsGetter = (params) => params.data.payment.promised_shows || '-'
  export const impressionsShowedGetter = (params) => params.data.payment.impressions_showed || '-'
  export const impressionsShowedInSalesPeriodGetter = (params) => params.data.payment.impressions_showed_in_sales_period || '-'

  export const stringFormatter = (params) => params.value || '-'
  export const stringCurrencyFormatter = (value) => {
    if (!value) return 0

    if (typeof value === 'string' && value.includes(','))
      value = value.replaceAll(',', '')
    var twoDecimals = parseFloat(value).toFixed(2)
    if (isNaN(twoDecimals)) return 'Número invalido'
    var formatPrice = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2
    }).format(twoDecimals)
    return `$ ${formatPrice}`
  }

  export const monthFormatter = (value) => {
    const months = [
      'Provisoria',
      'Enero',
      'Febrero', 
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre'
    ]
    return months[value] || '-'
  }
